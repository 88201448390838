export const zmRoutes = [
  {
    path: "/zm/",
    name: "ZM_HomePage",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/zm/network-support",
    name: "ZM_NetworkSupport",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/SupportView.vue"),
    meta: {
      title: "Report an Issue",
    },
  },
  {
    path: "/zm/company/about",
    name: "ZM_AboutPage",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/zm/company/leadership",
    name: "ZM_TeamPage",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/TeamPage.vue"),
    meta: {
      title: "Company's Leadership",
    },
  },
  {
    path: "/zm/company/solutions",
    name: "ZM_SolutionsView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/SolutionsView.vue"),
    meta: {
      title: "Efashe Solutions",
    },
  },
  {
    path: "/zm/company/contact-us",
    name: "ZM_ContactView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/zm/company/gallery",
    name: "ZM_GalleryView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/zm/company/careers",
    name: "ZM_VacanciesView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/VacanciesView.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/zm/company/careers/:slug",
    name: "ZM_VacancyDetails",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/VacancyDetails.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/zm/company/careers/:slug/apply",
    name: "ZM_VacancyApply",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/VacancyApply.vue"),
    meta: {
      title: "Apply",
    },
  },
  {
    path: "/zm/news",
    name: "ZM_NewsView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/NewsView.vue"),
    meta: {
      title: "News & Events",
    },
  },
  {
    path: "/zm/news/:slug",
    name: "ZM_ArticalDetails",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/ArticalDetails.vue"),
    meta: {
      title: "News",
    },
  },
  {
    path: "/zm/support/faq",
    name: "ZM_FAQView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/zm/support/legal",
    name: "ZM_LegalView",
    component: () =>
      import(/* webpackChunkName: "zm" */ "@/zm/views/LegalView.vue"),
    meta: {
      title: "Legal, Terms & Conditions and Privacy Policy",
    },
  },
];
