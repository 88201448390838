export const adminRoutes = [
  {
    path: "/admin/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/LoginView.vue"),
    meta: {
      title: "AdminLogin",
    },
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/DashboardView.vue"),
    meta: {
      title: "Dashboard",
      requireAuth: true,
    },
  },
  {
    path: "/admin/gallery",
    name: "PhotoGallery",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/PhotoGallery.vue"),
    meta: {
      title: "Photo Gallery",
      requireAuth: true,
    },
  },
  {
    path: "/admin/builder/:slug",
    name: "Builder",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/admin/views/BuilderView.vue"),
    meta: {
      title: "Builder",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/news",
    name: "BlogPosts",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/news/IndexView.vue"
      ),
    meta: {
      title: "News",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/news/categories",
    name: "BlogCategories",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/news/CategoriesView.vue"
      ),
    meta: {
      title: "Posts Categories",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/blog/:action/:slug?",
    name: "BlogCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/news/CreateView.vue"
      ),
    meta: {
      title: "Create Or Edit Post",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/jobs",
    name: "CreerJobs",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/jobs/IndexView.vue"
      ),
    meta: {
      title: "Jobs Opportunities",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/jobs/:action/:slug?",
    name: "JobCreator",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/admin/views/jobs/CreateView.vue"
      ),
    meta: {
      title: "Create Or Edit Job",
      requiresAdminAuth: true,
    },
  },
];
