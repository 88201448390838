export const mwRoutes = [
  {
    path: "/mw/",
    name: "MW_HomePage",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/mw/network-support",
    name: "MW_NetworkSupport",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/SupportView.vue"),
    meta: {
      title: "Report an Issue",
    },
  },
  {
    path: "/mw/company/about",
    name: "MW_AboutPage",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/mw/company/leadership",
    name: "MW_TeamPage",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/TeamPage.vue"),
    meta: {
      title: "Company's Leadership",
    },
  },
  {
    path: "/mw/company/solutions",
    name: "MW_SolutionsView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/SolutionsView.vue"),
    meta: {
      title: "Efashe Solutions",
    },
  },
  {
    path: "/mw/company/contact-us",
    name: "MW_ContactView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/mw/company/gallery",
    name: "MW_GalleryView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/mw/company/careers",
    name: "MW_VacanciesView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/VacanciesView.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/mw/company/careers/:slug",
    name: "MW_VacancyDetails",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/VacancyDetails.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/mw/company/careers/:slug/apply",
    name: "MW_VacancyApply",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/VacancyApply.vue"),
    meta: {
      title: "Apply",
    },
  },
  {
    path: "/mw/news",
    name: "MW_NewsView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/NewsView.vue"),
    meta: {
      title: "News & Events",
    },
  },
  {
    path: "/mw/news/:slug",
    name: "MW_ArticalDetails",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/ArticalDetails.vue"),
    meta: {
      title: "News",
    },
  },
  {
    path: "/mw/support/faq",
    name: "MW_FAQView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/mw/support/legal",
    name: "MW_LegalView",
    component: () =>
      import(/* webpackChunkName: "mw" */ "@/mw/views/LegalView.vue"),
    meta: {
      title: "Legal, Terms & Conditions and Privacy Policy",
    },
  },
];
