export const rwRoutes = [
  {
    path: "/rw/",
    name: "RW_HomePage",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/HomeView.vue"),
    meta: {
      title: "Welcome!",
    },
  },
  {
    path: "/rw/network-support",
    name: "RW_NetworkSupport",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/SupportView.vue"),
    meta: {
      title: "Report an Issue",
    },
  },
  {
    path: "/rw/company/about",
    name: "RW_AboutPage",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/rw/company/leadership",
    name: "RW_TeamPage",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/TeamPage.vue"),
    meta: {
      title: "Company's Leadership",
    },
  },
  {
    path: "/rw/company/solutions",
    name: "RW_SolutionsView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/SolutionsView.vue"),
    meta: {
      title: "Efashe Solutions",
    },
  },
  {
    path: "/rw/company/contact-us",
    name: "RW_ContactView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/rw/company/gallery",
    name: "RW_GalleryView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/GalleryView.vue"),
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/rw/company/careers",
    name: "RW_VacanciesView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/VacanciesView.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/rw/company/careers/:slug",
    name: "RW_VacancyDetails",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/VacancyDetails.vue"),
    meta: {
      title: "Vacancies",
    },
  },
  {
    path: "/rw/company/careers/:slug/apply",
    name: "RW_VacancyApply",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/VacancyApply.vue"),
    meta: {
      title: "Apply",
    },
  },
  {
    path: "/rw/news",
    name: "RW_NewsView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/NewsView.vue"),
    meta: {
      title: "News & Events",
    },
  },
  {
    path: "/rw/news/:slug",
    name: "RW_ArticalDetails",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/ArticalDetails.vue"),
    meta: {
      title: "News",
    },
  },
  {
    path: "/rw/support/faq",
    name: "RW_FAQView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/FAQView.vue"),
    meta: {
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/rw/support/legal",
    name: "RW_LegalView",
    component: () =>
      import(/* webpackChunkName: "rw" */ "@/rw/views/LegalView.vue"),
    meta: {
      title: "Legal, Terms & Conditions and Privacy Policy",
    },
  },
];
